<template>
	<div class="payGifts" v-loading="loading">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="display: inline-block;width:75px;text-align: right;">关键字: </label>
				<el-input v-model="searchKey" placeholder="活动名称" style="width: 250px;margin-right: 10px;" clearable></el-input>
			</div>

			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="searchState" style="width: 150px;margin-right: 10px;" @change="keyChange" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in searchStateList" :key="item.id" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<el-button type="primary" @click="goodsFilter">查询</el-button>
				<el-button style="margin-left: 15px;" @click="getNewGift({},2)">添加</el-button>
			</div>
		</div>

		<!-- 表格 -->
		<div class="table-container" style="margin-top:20px">
			<el-table :data="GiftData" style="width: 100%;" v-loading="loading" ref="multipleTable" :row-key="rowKeys">
				<el-table-column prop="RoomId" label="活动名称">
					<template slot-scope="scope">
						<div class="towLines">{{scope.row.Name}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="活动时间">
					<template slot-scope="scope">
						<div>{{scope.row.StartTime}}至</div>
						<div>{{scope.row.EndTime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="StateValue" label="状态">
					<template slot-scope="scope">
						<div>{{scope.row.ActivityStateShow}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="StateValue" label="支付订单">
					<template slot-scope="scope">
						<div v-if="scope.row.OrderCount>0" style="color:#409EFF;cursor: pointer;" @click="goOrderpage(scope.row)">{{scope.row.OrderCount}}</div>
						<div v-if="scope.row.OrderCount == 0">{{scope.row.OrderCount}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="PayCustomerCount" label="支付人数"></el-table-column>
				<el-table-column prop="PayMoney" label="支付金额"></el-table-column>
				<el-table-column prop="CustomerUnitPrice" label="客单价"></el-table-column>
				<el-table-column prop="SingleOrderUnitPrice" label="笔单价"></el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="240">
					<template slot-scope="scope">
						<button-permissions :datas="'editPayGifts'">
							<div class="table-button" v-if="scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中'" @click="getNewGift(scope.row,1)">编辑</div>
						</button-permissions>

						<!-- <el-button type="text" class="pointers" v-if="scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中'"
						 @click="getNewGift(scope.row,1)">编辑</el-button> -->
						<el-button type="text" class="pointers" v-if="scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中'"
						 @click="weiCodeFun(scope.row.Id)">推广</el-button>

						<el-button type="text" class="pointers" @click="lookGiftActive(scope.row)" v-if="scope.row.ActivityStateShow=='已结束'">查看</el-button>
						<button-permissions :datas="'deletePayGifts'">
							<div class="table-button" style="color:red;margin:0 10px" @click="delectFun(scope.row)" v-if="scope.row.ActivityStateShow=='已结束'">删除</div>
						</button-permissions>
						<!-- <span style="color:red;margin:0 10px" class="pointers" @click="delectFun(scope.row)" v-if="scope.row.ActivityStateShow=='已结束'">删除</span> -->

						<button-permissions :datas="'copyPayGifts'">
							<div class="table-button" style="color:#409EFF;margin:0 10px" v-if="scope.row.ActivityStateShow=='已结束'" @click="getNewGift(scope.row,2)">复制</div>
						</button-permissions>
						<!-- <el-button type="text" class="pointers" v-if="scope.row.ActivityStateShow=='已结束'" @click="getNewGift(scope.row,2)">复制</el-button> -->

						<el-dropdown style="margin-left:15px" v-if="(scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中')&&(showFinishBtn||showCopyBtn)">
							<span class="el-dropdown-link">
								<span style="color:#409EFF;cursor: pointer;">更多</span>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>
									<buttonPermissions :datas="'finishPayGifts'" @isShow='isShowFinish'>
										<div style="color:#409EFF;" @click="stopFun(scope.row)">使终止</div>
									</buttonPermissions>
									<!-- <span style="color:#409EFF;" @click="stopFun(scope.row)">使终止</span> -->
								</el-dropdown-item>
								<el-dropdown-item>
									<buttonPermissions :datas="'copyPayGifts'" @isShow='isShowCopy'>
										<div style="color:#409EFF;" @click="getNewGift(scope.row,2)">复制</div>
									</buttonPermissions>
									<!-- <span style="color:#409EFF;" @click="getNewGift(scope.row,2)">复制</span> -->
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>

					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: right;">
				<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<!-- 推广的弹框 -->
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel"></batch-cods>


	</div>
</template>

<script>
	import buttonPermissions from '@/components/buttonPermissions';
	import batchCods from "@/components/bathCodes"
	import {
		activityPayGiftList,
		activityPayGiftDelete,
		activityPayGiftOpen
	} from '@/api/goods'
	export default {
		components: {
			buttonPermissions,
			batchCods
		},
		data() {
			return {
				showFinishBtn:true,
				showCopyBtn:true,
				loading: false,
				searchKey: '',
				searchState: null,
				searchStateList: [{
						id: 0,
						value: '未开始'
					},
					{
						id: 1,
						value: '进行中'
					},
					{
						id: 2,
						value: '已结束'
					}
				],
				GiftData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				weiCodeShow: false,
				weiCodeData: {}


			}
		},
		beforeMount() {
			console.log('执行了吗，怎么完全没反应')
			this.getList();
		},
		methods: {
			isShowCopy(val){
				this.showCopyBtn = val
			},
			isShowFinish(val){
				this.showFinishBtn = val
			},
			async getList() {
				try {
					this.loading = true
					let data = {
						KeyWord: this.searchKey,
						ActivityState: this.searchState,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await activityPayGiftList(data)
					this.Total = result.Result.Total;
					this.GiftData = result.Result.Results || [];
					console.log(this.GiftData,'支付有礼数据')
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			goodsFilter() {
				this.currentPage = 1
				this.getList();
			},
			keyChange() {

			},
			goOrderpage(record) {
				this.$router.push({
					path: '/order/orderMange',
					query: {
						ActivityId: record.Id ? record.Id : 0,
						ActivityType: 9
					}
				});
			},
			// 查看活动
			lookGiftActive(item) {
				this.$router.push({
					path: '/market/payGifts/lookGiftActive',
					query: {
						Id: item.Id ? item.Id : 0,
					}
				});

			},

			// 添加
			getNewGift(item, type) {
				this.$router.push({
					path: '/market/payGifts/editPayGiftsDetail',
					query: {
						Id: item.Id ? item.Id : 0,
						type: type,
						status:item.ActivityStateShow ? item.ActivityStateShow : ''
					}
				});

			},
			rowKeys(row) {
				return row.Id
			},
			// 删除
			delectFun(row) {
				let message = '是否确认删除本场活动？删除后不可恢复'
				this.$confirm(message, '', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					dangerouslyUseHTMLString: true
				}).then(async () => {
					let data = {
						Id: row.Id
					}
					let result = await activityPayGiftDelete(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						let currentPageTotal = this.Total - (this.currentPage - 1) * this.pageSize
						if(currentPageTotal == 1 && this.currentPage!=1){
							this.currentPage -= 1
						}
						
						this.getList();
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {
					
				})
			},
			// 使终止
			stopFun(row) {
				let message = '活动终止后，你将不再在对这场活动进行编辑，是否确认终止？'
				this.$confirm(message, '', {
					confirmButtonText: '确认终止',
					cancelButtonText: '关闭',
					dangerouslyUseHTMLString: true
				}).then(async () => {
					let result = await activityPayGiftOpen({
						Id: row.Id
					})
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {
					this.getList();
				})
			},
			// 推广
			weiCodeFun(Id) {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: `pages/f/f`,
					Scene: `id=${Id}`
				}
			},
			weiCodeCancel(value) {
				this.weiCodeShow = value;
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},

		}
	}
</script>
<style lang="less" scoped>
	.payGifts {
		padding: 10px;
		background: #fff;
		.table-button{
			color: #409EFF;
			display: inline-block;
			margin-right: 15px;
			cursor: pointer;
		}
		.pointers {
			cursor: pointer;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
</style>
